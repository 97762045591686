<template>
  <div class="plate">
    <div class="parking">
      <img src="@/assets/parking.png" alt="" srcset="">
    </div>
    <div class="forms">
      <input type="text" v-model="plate" placeholder="请输入车牌号，例如：桂P12992">
      <van-cell is-link title="车牌颜色" :value="actionsValue" @click="show = true" />
    </div>
    <button type="submit" class="search" @click="search">查询</button>
    <van-action-sheet v-model="show" :actions="actions" close-on-click-action @select="onSelect" />
  </div>
</template>

<script>
export default {
  name: "searchPlate",
  title: "车牌号查询",
  data() {
    return {
      plate: "",
      plateColor: "1",
      show: false,
      actions: [
        { name: "蓝牌", value: "1" },
        { name: "绿牌", value: "5" },
        { name: "黄牌", value: "2" },
        { name: "白牌", value: "3" },
        { name: "黑牌", value: "4" },
      ],
      actionsValue: "蓝牌",
    };
  },
  watch: {
    plate: function (value) {
      this.plate = value.toUpperCase();
    },
  },
  created() {
    this.$parent.hiddenBack();
  },
  methods: {
    search() {
      if (this.plate.length > 6) {
        this.$router.push(
          `/bill?plate=${this.plate}&plateColor=${this.plateColor}`
        );
      } else {
        this.$toast.fail("请输入正确的车牌号");
      }
    },
    onSelect(item) {
      this.actionsValue = item.name;
      this.plateColor = item.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.plate {
  height: 100%;
  background-color: #fff;
  .parking {
    text-align: center;
    padding: 0.9rem 0 0.5rem 0;
    img {
      width: 6.2rem;
      height: auto;
    }
  }
  .forms {
    width: 6.5rem;
    margin: 0 auto;
    input {
      display: block;
      width: 100%;
      height: 0.9rem;
      margin: 0.3rem auto;
      background: #f3f3f3;
      border-radius: 8px;
      border: 0;
      text-align: center;
      font-size: 0.3rem;
    }
  }
  .search {
    display: block;
    width: 6.5rem;
    height: 0.9rem;
    margin: 2rem auto 0 auto;
    background: #1ca6ff;
    border-radius: 8px;
    border: 0;
    text-align: center;
    font-size: 0.3rem;
    color: #fff;
  }
}
</style>