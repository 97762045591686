import Vue from 'vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import App from './App.vue'
import router from './router'
import axios from 'cgan-axios'
import axiosConfig from './config/cgan-axios'
import components from './components/index'

Vue.config.productionTip = false
Vue.use(Vant);
Vue.use(axios, axiosConfig)
Vue.use(components)
Vue.prototype.$wxlogin = (back) => {
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_WCHART_APPID}&redirect_uri=${back}&response_type=code&scope=snsapi_base#wechat_redirect`
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
