<template>
  <div></div>
</template>

<script>
export default {
  name: "historyDetail",
  title: "订单详情",
};
</script>

<style>
</style>