<template>
  <div class="seatnum">
    <div class="parking">
      <img src="@/assets/parking.png" alt="" srcset="">
    </div>
    <input type="number" v-model="seatnum" placeholder="请输入车位线上车位编号">
    <button type="submit" @click="search">查询</button>
  </div>
</template>

<script>
export default {
  name: "searchSeatnum",
  title: "车位号查询",
  data() {
    return {
      seatnum: "",
    };
  },
  created() {
    this.$parent.hiddenBack();
  },
  methods: {
    search() {
      if (this.seatnum.length) {
        this.$router.push("/order?seatNum=" + this.seatnum);
      } else {
        this.$toast.fail("请输入车位码");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.seatnum {
  height: 100%;
  background-color: #fff;
  .parking {
    text-align: center;
    padding: 0.9rem 0 0.5rem 0;
    img {
      width: 6.2rem;
      height: auto;
    }
  }
  input {
    display: block;
    width: 6.5rem;
    height: 0.9rem;
    margin: 0.3rem auto;
    background: #f3f3f3;
    border-radius: 8px;
    border: 0;
    text-align: center;
    font-size: 0.3rem;
  }
  button {
    display: block;
    width: 6.5rem;
    height: 0.9rem;
    margin: 2rem auto 0 auto;
    background: #1ca6ff;
    border-radius: 8px;
    border: 0;
    text-align: center;
    font-size: 0.3rem;
    color: #fff;
  }
}
</style>