import { Toast, Dialog } from 'vant'

export default {
    baseURL: process.env.VUE_APP_BASEURL,
    headers: {
        'Content-Type': 'application/json'
    },
    tokenPosition: 'localStorage',
    tokenKey: 'Authorization',
    successCode: 200,
    mapCodeFunction: {
        401: (message) => {
            Toast.fail({ message: message, duration: 3000 });
            window.location.href = 'https://h5.weibozn.com/login'
        },
        400: (message) => {
            Dialog.alert({
                title: '提示',
                message: message,
            }).then(() => { });
        }
    },
    toast: (message) => {
        Toast(message);
    },
    toastSuccess: (message) => {
        Toast.success(message);
    },
    toastWarning: (message) => {
        Toast.fail({ message: message, duration: 3000 });
    },
    showloading: () => {
        window.loadingInstance = Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            overlay: true,
            message: "加载中",
        })
        return window.loadingInstance
    },
    hiddenloading: () => {
        window.loadingInstance.clear()
    },
    confirm: () => {
        return Dialog.confirm({
            title: '提示',
            message: '您正在进行删除操作，是否确定继续？'
        })
    }
}