<template>
  <div>
    <div class="history-content">
      <div class="item" v-for="(it,key) in arrearsList" :key="key">
        <p class="plate"><span :class="it.isNow?'isNow1':'isNow0'">{{it.isNow?'当前订单':'历史订单'}}</span>{{it.plate}}</p>
        <p class="location"><img src="@/assets/location.png" alt="" srcset="">{{it.parkName}}</p>
        <p class="location"><img src="@/assets/time.png" alt="" srcset="">{{it|times}}</p>
        <p class="time">进场时间：{{it.enterTime}}</p>
        <p class="time red" v-if="it.leaveTime">出场时间：{{it.leaveTime}}</p>
        <div class="line"></div>
        <div class="price">
          <p>￥{{it.payAmount.toFixed(2)}}</p>
          <p @click="imagePreview(it.inPic)">查看入场照片</p>
        </div>
      </div>
    </div>
    <div class="paybox">
      <div class="price">共有<span>{{arrearsList.length}}</span>笔，总额<span>{{arrearsList|totalPrice}}</span>元</div>
      <div class="pay-btn" @click="show=true">立即付款</div>
    </div>

    <van-popup v-model="show" position="bottom">
      <div class="popup-box">
        <p class="title">请选择付款方式</p>
        <div class="pay-type" @click="payType='ali'" v-if="canUseAli">
          <img src="@/assets/ali.png" alt="" srcset="">
          <p>支付宝支付</p>
          <img :src="payType=='ali'?selectImg:unselectImg" alt="" srcset="">
        </div>
        <div class="pay-type" @click="payType='wx'" v-if="canUseWx">
          <img src="@/assets/wx.png" alt="" srcset="">
          <p>微信支付</p>
          <img :src="payType=='wx'?selectImg:unselectImg" alt="" srcset="">
        </div>
        <div class="pay-type" @click="payType='wxh5'" v-if="canUseWxh5">
          <img src="@/assets/wx.png" alt="" srcset="">
          <p>微信支付</p>
          <img :src="payType=='wxh5'?selectImg:unselectImg" alt="" srcset="">
        </div>
        <div class="pay-btn">
          <div class="pay-btn-group">
            <p @click="show=false">取消</p>
            <p @click="pay">确定</p>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import selectImg from "@/assets/select.png";
import unselectImg from "@/assets/unselect.png";
import { ImagePreview } from "vant";
export default {
  name: "bill",
  title: "我的账单",
  data() {
    return {
      aliAppId: process.env.VUE_APP_ALI_APPID,
      show: false,
      payType: "",
      canUseAli: false,
      canUseWx: false,
      canUseWxh5: false,
      selectImg: selectImg,
      unselectImg: unselectImg,
      arrearsList: [],
    };
  },
  filters: {
    times: function (value) {
      if (value.enterTime) {
        let leaveTime = value.leaveTime || new Date().getTime();
        let times =
          new Date(leaveTime).getTime() - new Date(value.enterTime).getTime();
        let resHours = 0,
          resMin = 0,
          resSec = 0;
        resSec = Math.floor(times / 1000);
        if (resSec > 59) {
          resMin = Math.floor(resSec / 60);
          if (resMin > 59) {
            resHours = Math.floor(resMin / 60);
            resMin = resMin - resHours * 60;
            resSec = resSec - resMin * 60 - resHours * 3600;
            return resHours + "时" + resMin + "分" + resSec + "秒";
          } else {
            resSec = resSec - resMin * 60;
            return resMin + "分" + resSec + "秒";
          }
        } else {
          return resSec + "秒";
        }
      } else {
        return "--";
      }
    },
    totalPrice: function (value) {
      let price = 0;
      value.forEach((e) => {
        price += e.payAmount;
      });
      return price.toFixed(2);
    },
  },
  created() {
    this.$parent.hiddenBack();
    let plate = this.$route.query.plate;
    let plateColor = this.$route.query.plateColor;
    if (plate) {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf("micromessenger") > 0) {
        if (!this.$route.query.code) {
          this.$wxlogin(encodeURIComponent(location.href));
        } else {
          this.canUseWx = true;
          this.payType = "wx";
          this.getOrderByPlate(
            plate,
            this.payType,
            this.$route.query.code,
            plateColor
          );
        }
      } else if (ua.indexOf("alipayclient") > 0) {
        this.canUseAli = true;
        this.payType = "ali";
        window.ap.getAuthCode(
          {
            appId: this.aliAppId,
            scopes: ["auth_base"],
            showErrorTip: false,
          },
          (res) => {
            this.getOrderByPlate(plate, this.payType, res.authCode, plateColor);
          }
        );
      } else {
        this.canUseWxh5 = this.canUseAli = true;
        this.payType = "h5";
        this.$toast({
          duration: 5000,
          message: "为保证信息安全\n请使用微信或支付宝扫码",
        });
      }
    } else {
      this.$toast({
        type: "fail",
        duration: 0,
        message: "账单二维码信息异常，请与收费员核实",
      });
    }
  },
  methods: {
    getOrderByPlate(plate, payType, code, plateColor) {
      this.$http.post(
        "/park-service-order/order/selectOrderInfoByPlate",
        { plate, payType, code, plateColor },
        (res) => {
          if (res.orderOutDTO.length > 0) {
            res.orderOutDTO.forEach((e) => {
              if (e.imageArrays) {
                let obj = JSON.parse(e.imageArrays),
                  arr = [];
                for (const key in obj) {
                  if (Object.hasOwnProperty.call(obj, key)) {
                    arr.push(obj[key]);
                  }
                }
                e.inPic = arr;
              } else {
                e.inPic = [e.inPic];
              }
            });
            this.arrearsList = res.orderOutDTO.sort((x, y) => {
              return y.isNow - x.isNow;
            });
          } else {
            this.$dialog
              .alert({
                title: "提示",
                message: `未查询到当前车辆 ${plate} 的订单信息`,
                theme: "round-button",
                confirmButtonText: "知道了",
              })
              .then(() => {});
          }
        }
      );
    },
    // 聚合支付
    pay() {
      let orderIds = [];
      this.arrearsList.forEach((e) => {
        if (e.payAmount > 0) {
          orderIds.push(e.id);
        }
      });
      // 支付宝
      if (this.payType == "ali") {
        this.$http.post(
          "/park-service-order/pay/payOrder",
          {
            application: "0",
            code: "H5",
            orderIds: orderIds,
            payType: this.payType,
            returnUrl: location.origin + "/success",
          },
          (res) => {
            document.write(res.resultInfo);
          }
        );
      }
      // 微信支付
      if (this.payType == "wx" || this.payType == "wxh5") {
        this.$http.post(
          "/park-service-order/pay/payOrder",
          {
            application: "0",
            code: this.$route.query.code || "H5",
            orderIds: orderIds,
            payType: this.payType,
          },
          (res) => {
            res.package = res.packageValue;
            window.WeixinJSBridge.invoke(
              "getBrandWCPayRequest",
              res,
              (payres) => {
                if (payres.err_msg == "get_brand_wcpay_request:ok") {
                  localStorage.orderId = "";
                  if (this.arrearsList[0].isNow == 1) {
                    localStorage.orderId = this.arrearsList[0].id;
                  }
                  this.$router.replace("/success");
                } else {
                  this.$toast.fail("支付取消，请重新扫码");
                }
              }
            );
          }
        );
      }
    },
    imagePreview(inPic) {
      ImagePreview({
        images: inPic,
        closeable: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.history-content {
  height: calc(100% - 1.16rem);
  overflow-y: auto;
  .item {
    width: 6.4rem;
    padding: 0.25rem;
    background-color: #fff;
    border-radius: 12px;
    margin: 0.22rem auto 0 auto;
    p {
      margin: 0.25rem 0;
      font-size: 0.28rem;
    }
    .plate {
      font-size: 0.32rem;
      font-weight: bold;
      span {
        font-weight: normal;
        font-size: 0.28rem;
        margin-right: 5px;
        border-radius: 5px;
        padding: 1px 5px;
      }
      .isNow1 {
        background-color: #fa8500;
        color: #fff;
      }
      .isNow0 {
        background-color: #999;
        color: #fff;
      }
    }
    .location {
      img {
        width: 0.24rem;
        height: 0.24rem;
        vertical-align: middle;
        margin-right: 0.05rem;
      }
    }
    .time {
      color: #8e8e8e;
      padding-left: 0.3rem;
      position: relative;
      &::before {
        position: absolute;
        z-index: 1;
        left: 0.05rem;
        top: 0.14rem;
        content: "";
        display: block;
        width: 0.12rem;
        height: 0.12rem;
        border-radius: 50%;
        background-color: #03c891;
      }
    }
    .red {
      &::before {
        background-color: #ec2e6b;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: #f0f3f0;
      margin: 0.28rem auto;
    }
    .price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0;
        &:first-child {
          color: #1ca6ff;
          font-size: 0.36rem;
        }
        &:last-child {
          background-color: #1ca6ff;
          color: #fff;
          padding: 0.12rem 0.22rem;
          border-radius: 50px;
        }
      }
    }
  }
}
.paybox {
  height: 1.16rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  font-size: 0.3rem;
  line-height: 1.16rem;
  .price {
    padding-left: 0.54rem;
    span {
      color: #fa8500;
      font-size: 0.48rem;
    }
  }
  .pay-btn {
    color: #fff;
    font-size: 0.36rem;
    padding: 0 0.37rem;
    background: linear-gradient(270deg, #1a92fe 0%, #55bafd 80%);
  }
}
.popup-box {
  .title {
    font-size: 0.3rem;
    font-weight: bold;
    padding: 0 0.47rem;
    margin: 0.4rem 0;
  }
  .pay-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.43rem;
    margin: 0.43rem 0;
    img {
      width: 0.44rem;
      height: 0.44rem;
    }
    p {
      flex: 1;
      padding: 0 0.14rem;
      font-size: 0.28rem;
    }
  }
  .pay-btn {
    margin-top: 1.1rem;
    height: 1.16rem;
    box-shadow: 0px -8px 16px 0px rgba(237, 237, 237, 0.55);
    .pay-btn-group {
      float: right;
      margin-top: 0.23rem;
      margin-right: 0.3rem;
      width: 3.74rem;
      height: 0.7rem;
      display: flex;
      border-radius: 100px;
      overflow: hidden;
      p {
        flex: 1;
        text-align: center;
        background: #03c891;
        color: #fff;
        line-height: 0.7rem;
        &:last-child {
          background-color: #1ca6ff;
        }
      }
    }
  }
}
</style>