<template>
  <div class="title">
    <van-icon v-if="showBack" class="icon left" name="arrow-left" @click="$router.back()" />
    {{title}}
    <a v-if="showService" :href="'tel:+'+configValue" class="icon right">
      <van-icon name="service" />
    </a>
  </div>
</template>

<script>
export default {
  name: "componentsTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    showService: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      configValue: "",
    };
  },
  created() {
    if (this.showService) {
      this.$http.get(
        "/park-service-system/config/selectValueByKey",
        { key: "connect_tell" },
        (res) => {
          this.configValue = res.configValue;
        },
        false
      );
    }
  },
};
</script>

<style scoped>
.title {
  position: relative;
  height: 0.8rem;
  background-color: #fff;
  font-size: 0.38rem;
  text-align: center;
  line-height: 0.8rem;
  font-weight: bold;
  padding: 0 0.24rem;
  box-shadow: 0 0 5px #eee;
}
.icon {
  position: absolute;
  z-index: 9;
  top: 0.2rem;
}
.left {
  left: 0.2rem;
}
.right {
  right: 0.2rem;
  top: 0;
}
.right i {
  color: #1f2020;
  line-height: 0.8rem;
}
</style>