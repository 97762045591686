import Vue from 'vue'
import VueRouter from 'vue-router'

var routes = []

// autoRouter文件夹自动路由渲染
const ctx = require.context('../views/autoRouter', true, /\.vue$/)
routes.push({
  path: '',
  name: 'autoRouterParent',
  component: () => import('../views/autoRouterparent.vue'),
  children: []
})
for (const key of ctx.keys()) {
  let vueModule = ctx(key), splitArr = key.slice(1).replace('.vue', '').split('/')
  routes[0].children.push({
    path: splitArr.join('/'),
    name: vueModule.default.name,
    meta: {
      title: vueModule.default.title
    },
    component: vueModule.default
  })
}
routes.unshift({
  path: '/',
  redirect: '/order'
})
routes.push.apply(routes, [{
  path: '/order',
  name: 'order',
  meta: {
    title: "当前订单"
  },
  component: () => import('../views/order.vue')
}, {
  path: '/code',
  name: 'code',
  meta: {
    title: "车位码解析"
  },
  component: () => import('../views/code.vue')
}, {
  path: '/success',
  name: 'success',
  meta: {
    title: "支付成功"
  },
  component: () => import('../views/success.vue')
}, {
  path: '/register',
  name: 'register',
  meta: {
    title: "新用户注册"
  },
  component: () => import('../views/register.vue')
}, {
  path: '/payinfo',
  name: 'payinfo',
  meta: {
    title: "支付订单详情"
  },
  component: () => import('../views/payinfo.vue')
}, {
  path: '*',
  name: 'nofount',
  meta: {
    title: "资源文件未找到"
  },
  component: () => import('../views/notFound.vue')
}])
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}-${process.env.VUE_APP_PROJECT_NAME}快捷支付`
  next()
})
export default router
