<template>
  <div class="phone" v-if="configValue">
    <a :href="'tel:+'+configValue" class="ahref">
      <span>客服电话：</span>
      <span>{{configValue}}</span>
    </a>
    <p class="copy">如有疑问或支付遇到问题，请点击拨打客服电话咨询反馈</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      configValue: "",
    };
  },
  created() {
    this.$http.get(
      "/park-service-system/config/selectValueByKey",
      { key: "connect_tell" },
      (res) => {
        this.configValue = res.configValue;
      },
      false
    );
  },
};
</script>

<style>
.phone {
  text-align: center;
}
.ahref {
  color: #1f2020;
  line-height: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.copy{
    font-size: 12px;
    color: #999;
}
</style>